a[role='button'] {
  cursor: pointer;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: var(--text-color) !important;
  --mdc-text-button-label-text-color: var(--text-color) !important;
  --mdc-outlined-button-label-text-color: var(--text-color) !important;
  background-color: white !important;
  border-color: transparent !important;
  padding: 0 30px !important;
}

.mdc-button__label {
  i {
    color: inherit;
  }
}

.buttons {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  &.end {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.full {
    button {
      width: 100%;
    }
  }
}
