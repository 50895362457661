::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: var(--scroll-track);
  border-radius: 6px;
}
