@import 'palette';

@import 'ngx-toastr/toastr';
@import 'styles/animations';
@import 'styles/button';
@import 'styles/dialog';
@import 'styles/hotfixes';
@import 'styles/input';
@import 'styles/media';
@import 'styles/page';
@import 'styles/scroll';
@import 'styles/skeleton';
@import 'styles/table';
@import 'styles/typography';
@import 'styles/variables';
@import 'styles/fw2svg';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: white;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth; /* Rolagem suave */
}
