@use '@angular/material' as mat;

.medium-zoom-overlay {
  z-index: 1;
  backdrop-filter: blur(4px);
}
.medium-zoom-image--opened {
  z-index: 2;
}
.cdk-overlay-dark-backdrop {
  backdrop-filter: blur(4px);
}
.toast-container .ngx-toastr {
  @include mat.elevation(8);
  @include mat.elevation-transition();
  &:hover {
    @include mat.elevation(2);
  }
}
