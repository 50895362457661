:root {
  font-size: calc(16px + 0.3125vw);
  --ts: all 0.3s ease-in-out;
  --br: 0.5rem;
  --elastic: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  --text-color: #0e0e0e;
  --primary-color: #f5c738;
  --primary-bg: #fafafa;
  --secondary-bg: #eeeeee;
  --tertiary-bg: #e3e3e3;
  --line-color: #dbdbdb;

  --yellow-color: #f2af4c;
  --green-color: #5dc983;
  --red-color: #e0324f;
  --gray-color: #cccccc;

  --skeleton-bg: var(--secondary-bg);
  --skeleton-bg-dark: #e7e7e7;

  --avatar-dark-color: #e9e9e9;
  --avatar-light-color: #ffffff;
  --avatar-br: 50%;
  --avatar-ratio: 1/1;

  --medium-zoom-overlay-bg: rgba(255, 255, 255, 0.32);

  --container: 1440px;

  // ? Scrollbar
  --scroll-track: var(--secondary-bg);
  --scroll-thumb: var(--tertiary-bg);
}
